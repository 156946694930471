import React, { Component } from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import {
    getTradeInTransactionDetails,
    getTradeInTransactionQuestionnaire,
    getTradeInTransactionEligibleArticles,
    cancelTradeInTransaction,
    confirmTradeInTransaction,
    evaluateTradeInPrice,
} from "../actions/tradeInTransactionsAction";
import { withTranslation } from "react-i18next";
import TradeInTransactionEditView from "../components/tradeInTransaction/TradeInTransactionEditView";
import { showError } from "../actions/commonActions";

class TradeInTransactionEdit extends Component {

    componentDidMount() {
        this.props.getTradeInTransactionQuestionnaire()
        this.props.getTradeInTransactionDetails(this.props.match.params.id).then(() => {
            this.props.getTradeInTransactionEligibleArticles(this.props.tradeIns.tradeInTransaction.countryCode, this.props.tradeIns.tradeInTransaction.carrierCode)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            window.setTimeout(() => { this.props.history.push(`/tradeInTransactions/${this.props.match.params.id}`); }, 2000)
        }
    }

    onCancelTradeInTransaction = (id) => {
        this.props.cancelTradeInTransaction(id, 'cancel');
    }

    onConfirmTradeInTransaction = (id, payload) => {
        this.props.confirmTradeInTransaction(id, payload);
    }

    onEvaluateTradeInPrice = (id, payload) => {
        this.props.evaluateTradeInPrice(id, payload);
    }

    renderDetails = (tradeInTransaction, questionnaire, eligibleArticles, authorities, countryCode) => {
        return (
            <TradeInTransactionEditView 
                tradeInTransaction={tradeInTransaction} 
                questionnaire={questionnaire}
                eligibleArticles={eligibleArticles}
                userAuthorities={authorities}
                userCountryCode={countryCode}
                onCancelTradeInTransaction={id => this.onCancelTradeInTransaction(id)}
                onConfirmTradeInTransaction={(id, payload) => this.onConfirmTradeInTransaction(id, payload)}
                onEvaluateTradeInPrice={(id, payload) => this.onEvaluateTradeInPrice(id, payload)}>
            </TradeInTransactionEditView>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="">
                {
                    this.renderDetails(this.props.tradeIns.tradeInTransaction, this.props.tradeIns.tradeInTransactionQuestionnaire, this.props.tradeIns.tradeInTransactionEligibleArticles, 
                        this.props.authentication.loggedInUser.authorities, this.props.authentication.loggedInUser.countryCode)

                }
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    tradeIns: state.tradeIns,
    common: state.common
})

export default connect(mapStateToProps, {
    getTradeInTransactionDetails,
    getTradeInTransactionQuestionnaire,
    getTradeInTransactionEligibleArticles,
    cancelTradeInTransaction,
    confirmTradeInTransaction,
    evaluateTradeInPrice,
    showError,
})(withTranslation()(TradeInTransactionEdit));