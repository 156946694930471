import React, { Component } from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import {
    getTradeInTransactionDetails,
    cancelTradeInTransaction
} from "../actions/tradeInTransactionsAction";
import { withTranslation } from "react-i18next";
import TradeInTransactionDetailsView from "../components/tradeInTransaction/TradeInTransactionDetailsView";
import { showError } from "../actions/commonActions";

class TradeInTransactionDetails extends Component {

    componentDidMount() {
        this.props.getTradeInTransactionDetails(this.props.match.params.id)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            window.setTimeout(() => { window.location.reload(true); }, 2000)
        }
    }

    onCancelTradeInTransaction = (id) => {
        this.props.cancelTradeInTransaction(id, 'cancel');
    }

    renderDetails = (tradeInTransaction, authorities) => {
        return (
            <TradeInTransactionDetailsView 
                tradeInTransaction={tradeInTransaction} 
                userAuthorities={authorities}
                onCancelTradeInTransaction={id => this.onCancelTradeInTransaction(id)}>
            </TradeInTransactionDetailsView>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="Trade In Transactions Details">
                {
                    this.renderDetails(this.props.tradeIns.tradeInTransaction, this.props.authentication.loggedInUser.authorities)

                }
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    tradeIns: state.tradeIns,
    common: state.common
})

export default connect(mapStateToProps, {
    getTradeInTransactionDetails,
    cancelTradeInTransaction,
    showError,
})(withTranslation()(TradeInTransactionDetails));